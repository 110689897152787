// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import DynamicFormSettingsModel from '../../model/dynamic-form-settings-model';
import DynamicFormSettingsDTO from '../dto/dynamic-form-settings-dto';
import dtoSchema from '../schemas/dynamic-form-settings-schema';

export default abstract class DynamicFormSettingsModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<DynamicFormSettingsDTO>) {
    super();
    if (data) {
      this.mandatoryEmail = data.mandatoryEmail;
      this.mandatoryAddress = data.mandatoryAddress;
      this.hideAddress = data.hideAddress;
      this.hideCompanyName = data.hideCompanyName;
      this.hideDateOfBirth = data.hideDateOfBirth;
      this.hidePhoneNumber = data.hidePhoneNumber;
      this.hideMobilePhoneNumber = data.hideMobilePhoneNumber;
      this.hideHouseNumber = data.hideHouseNumber;
      this.hideAddressLine1 = data.hideAddressLine1;
      this.hidePOBox = data.hidePOBox;
      this.hideCountry = data.hideCountry;
      this.maxWidthInPixels = data.maxWidthInPixels;
      this.paddingInPixels = data.paddingInPixels;
      this.contactGroupTitle = data.contactGroupTitle ?? undefined;
    }
  }
  /**
  */
  mandatoryEmail?: boolean;
  /**
  */
  mandatoryAddress?: boolean;
  /**
  */
  hideAddress?: boolean;
  /**
  */
  hideCompanyName?: boolean;
  /**
  */
  hideDateOfBirth?: boolean;
  /**
  */
  hidePhoneNumber?: boolean;
  /**
  */
  hideMobilePhoneNumber?: boolean;
  /**
  */
  hideHouseNumber?: boolean;
  /**
  */
  hideAddressLine1?: boolean;
  /**
  */
  hidePOBox?: boolean;
  /**
  */
  hideCountry?: boolean;
  /**
  * @type {int32}
  */
  maxWidthInPixels?: number;
  /**
  * @type {int32}
  */
  paddingInPixels?: number;
  /**
  */
  contactGroupTitle?: string;

  static toModel(dto: DeepPartial<DynamicFormSettingsDTO>): DynamicFormSettingsModel;
  static toModel(dto: DeepPartial<DynamicFormSettingsDTO> | undefined | null): DynamicFormSettingsModel | undefined;
  static toModel(dto: DeepPartial<DynamicFormSettingsDTO> | undefined | null): DynamicFormSettingsModel | undefined {
    return dto ? new DynamicFormSettingsModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<DynamicFormSettingsModel> | DynamicFormSettingsModel): DynamicFormSettingsDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      mandatoryEmail: unwrapped.mandatoryEmail,
      mandatoryAddress: unwrapped.mandatoryAddress,
      hideAddress: unwrapped.hideAddress,
      hideCompanyName: unwrapped.hideCompanyName,
      hideDateOfBirth: unwrapped.hideDateOfBirth,
      hidePhoneNumber: unwrapped.hidePhoneNumber,
      hideMobilePhoneNumber: unwrapped.hideMobilePhoneNumber,
      hideHouseNumber: unwrapped.hideHouseNumber,
      hideAddressLine1: unwrapped.hideAddressLine1,
      hidePOBox: unwrapped.hidePOBox,
      hideCountry: unwrapped.hideCountry,
      maxWidthInPixels: unwrapped.maxWidthInPixels,
      paddingInPixels: unwrapped.paddingInPixels,
      contactGroupTitle: unwrapped.contactGroupTitle,
    } as DynamicFormSettingsDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for DynamicFormSettingsModel, can not map to DynamicFormSettingsDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
