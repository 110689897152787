// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/dynamic-form-settings-schema',
  type: 'object',
  required: ['maxWidthInPixels', 'paddingInPixels'],
  properties: {
    mandatoryEmail: {
      type: 'boolean',
    },
    mandatoryAddress: {
      type: 'boolean',
    },
    hideAddress: {
      type: 'boolean',
    },
    hideCompanyName: {
      type: 'boolean',
    },
    hideDateOfBirth: {
      type: 'boolean',
    },
    hidePhoneNumber: {
      type: 'boolean',
    },
    hideMobilePhoneNumber: {
      type: 'boolean',
    },
    hideHouseNumber: {
      type: 'boolean',
    },
    hideAddressLine1: {
      type: 'boolean',
    },
    hidePOBox: {
      type: 'boolean',
    },
    hideCountry: {
      type: 'boolean',
    },
    maxWidthInPixels: {
      type: 'integer',
    },
    paddingInPixels: {
      type: 'integer',
    },
    contactGroupTitle: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
