// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/location-city-search-schema',
  type: 'object',
  required: ['id', 'creatorId', 'stateId', 'countryId'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    creatorId: {
      type: 'integer',
    },
    creatorName: {
      type: 'string',
      nullable: true,
    },
    created: {
      type: 'string',
      format: 'date-time',
    },
    modifierId: {
      type: 'integer',
      nullable: true,
    },
    modifierName: {
      type: 'string',
      nullable: true,
    },
    modified: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    postalCode: {
      type: 'string',
      nullable: true,
    },
    postalCodeAddition: {
      type: 'string',
      nullable: true,
    },
    city: {
      type: 'string',
      nullable: true,
    },
    stateId: {
      type: 'integer',
    },
    stateCode: {
      type: 'string',
      nullable: true,
    },
    stateName: {
      type: 'string',
      nullable: true,
    },
    countryId: {
      type: 'integer',
    },
    countryCode: {
      type: 'string',
      nullable: true,
    },
    countryName: {
      type: 'string',
      nullable: true,
    },
    language_ISO2: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
