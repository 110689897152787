// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/dynamic-form-contact-schema',
  type: 'object',
  required: ['countryId'],
  properties: {
    salutation: {
      enum: [
        0,
        1,
        2,
        3,
      ],
    },
    companyName: {
      type: 'string',
      nullable: true,
    },
    firstName: {
      type: 'string',
      nullable: true,
    },
    lastName: {
      type: 'string',
      nullable: true,
    },
    street: {
      type: 'string',
      nullable: true,
    },
    houseNumber: {
      type: 'string',
      nullable: true,
    },
    addressLine1: {
      type: 'string',
      nullable: true,
    },
    poBox: {
      type: 'string',
      nullable: true,
    },
    countryId: {
      type: 'integer',
    },
    countryIso2: {
      type: 'string',
      nullable: true,
    },
    postalCode: {
      type: 'string',
      nullable: true,
    },
    city: {
      type: 'string',
      nullable: true,
    },
    phoneNumber: {
      type: 'string',
      nullable: true,
    },
    mobilePhoneNumber: {
      type: 'string',
      nullable: true,
    },
    email: {
      type: 'string',
      nullable: true,
    },
    dateOfBirth: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
