// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/set-password-for-user-request-schema',
  type: 'object',
  required: ['newPassword', 'repeatNewPassword', 'id'],
  properties: {
    newPassword: {
      type: 'string',
      minLength: 6,
    },
    repeatNewPassword: {
      type: 'string',
      minLength: 6,
    },
    id: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
