// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LocationCitySearchModel from '../../model/location-city-search-model';
import LocationCitySearchDTO from '../dto/location-city-search-dto';
import dtoSchema from '../schemas/location-city-search-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class LocationCitySearchModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LocationCitySearchDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.postalCode = data.postalCode ?? undefined;
      this.postalCodeAddition = data.postalCodeAddition ?? undefined;
      this.city = data.city ?? undefined;
      this.stateId = data.stateId;
      this.stateCode = data.stateCode ?? undefined;
      this.stateName = data.stateName ?? undefined;
      this.countryId = data.countryId;
      this.countryCode = data.countryCode ?? undefined;
      this.countryName = data.countryName ?? undefined;
      this.language_ISO2 = data.language_ISO2 ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  */
  postalCode?: string;
  /**
  */
  postalCodeAddition?: string;
  /**
  */
  city?: string;
  /**
  * @type {int64}
  */
  stateId?: number;
  /**
  */
  stateCode?: string;
  /**
  */
  stateName?: string;
  /**
  * @type {int64}
  */
  countryId?: number;
  /**
  */
  countryCode?: string;
  /**
  */
  countryName?: string;
  /**
  */
  language_ISO2?: string;

  static toModel(dto: DeepPartial<LocationCitySearchDTO>): LocationCitySearchModel;
  static toModel(dto: DeepPartial<LocationCitySearchDTO> | undefined | null): LocationCitySearchModel | undefined;
  static toModel(dto: DeepPartial<LocationCitySearchDTO> | undefined | null): LocationCitySearchModel | undefined {
    return dto ? new LocationCitySearchModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LocationCitySearchModel> | LocationCitySearchModel): LocationCitySearchDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      postalCode: unwrapped.postalCode,
      postalCodeAddition: unwrapped.postalCodeAddition,
      city: unwrapped.city,
      stateId: unwrapped.stateId,
      stateCode: unwrapped.stateCode,
      stateName: unwrapped.stateName,
      countryId: unwrapped.countryId,
      countryCode: unwrapped.countryCode,
      countryName: unwrapped.countryName,
      language_ISO2: unwrapped.language_ISO2,
    } as LocationCitySearchDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LocationCitySearchModel, can not map to LocationCitySearchDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
