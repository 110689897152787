// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CallLogWithDurationModel from '../../model/call-log-with-duration-model';
import CallLogWithDurationDTO from '../dto/call-log-with-duration-dto';
import dtoSchema from '../schemas/call-log-with-duration-schema';
import ResourceLinkModel from '../../model/resource-link-model';

export default abstract class CallLogWithDurationModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CallLogWithDurationDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.targetSystemName = data.targetSystemName ?? undefined;
      this.correlationId = data.correlationId ?? undefined;
      this.requestTime = data.requestTime;
      this.requestType = data.requestType ?? undefined;
      this.requestUrl = data.requestUrl ?? undefined;
      this.requestPath = data.requestPath ?? undefined;
      this.requestBody = data.requestBody ?? undefined;
      this.responseTime = data.responseTime ?? undefined;
      this.responseStatusCode = data.responseStatusCode ?? undefined;
      this.responseBody = data.responseBody ?? undefined;
      this.responseInfo = data.responseInfo ?? undefined;
      this.exceptionMessage = data.exceptionMessage ?? undefined;
      this.responseDuration = data.responseDuration;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  */
  targetSystemName?: string;
  /**
  */
  correlationId?: string;
  /**
  * @type {date-time}
  */
  requestTime?: string;
  /**
  */
  requestType?: string;
  /**
  */
  requestUrl?: string;
  /**
  */
  requestPath?: string;
  /**
  */
  requestBody?: string;
  /**
  * @type {date-time}
  */
  responseTime?: string;
  /**
  * @type {int32}
  */
  responseStatusCode?: number;
  /**
  */
  responseBody?: string;
  /**
  */
  responseInfo?: string;
  /**
  */
  exceptionMessage?: string;
  /**
  * @type {double}
  */
  responseDuration?: number;

  static toModel(dto: DeepPartial<CallLogWithDurationDTO>): CallLogWithDurationModel;
  static toModel(dto: DeepPartial<CallLogWithDurationDTO> | undefined | null): CallLogWithDurationModel | undefined;
  static toModel(dto: DeepPartial<CallLogWithDurationDTO> | undefined | null): CallLogWithDurationModel | undefined {
    return dto ? new CallLogWithDurationModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CallLogWithDurationModel> | CallLogWithDurationModel): CallLogWithDurationDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      targetSystemName: unwrapped.targetSystemName,
      correlationId: unwrapped.correlationId,
      requestTime: unwrapped.requestTime,
      requestType: unwrapped.requestType,
      requestUrl: unwrapped.requestUrl,
      requestPath: unwrapped.requestPath,
      requestBody: unwrapped.requestBody,
      responseTime: unwrapped.responseTime,
      responseStatusCode: unwrapped.responseStatusCode,
      responseBody: unwrapped.responseBody,
      responseInfo: unwrapped.responseInfo,
      exceptionMessage: unwrapped.exceptionMessage,
      responseDuration: unwrapped.responseDuration,
    } as CallLogWithDurationDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CallLogWithDurationModel, can not map to CallLogWithDurationDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
