// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetIndividualProjectByIdRequestModel from '../../model/get-individual-project-by-id-request-model';
import MyIndividualModel from '../../model/my-individual-model';

export default (service: Servicelayer) => ({
  /**  */
  async getIndividualProjectById(request: GetIndividualProjectByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetIndividualProjectByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-individual-project-by-id'] ?? 'IndividualProjects/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(MyIndividualModel.toModel(dto));
  },
});
