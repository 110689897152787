// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2023 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/get-call-logs-request-schema',
  type: 'object',
  required: [],
  properties: {
    correlationId: {
      type: 'string',
    },
    from: {
      type: 'string',
      format: 'date-time',
    },
    to: {
      type: 'string',
      format: 'date-time',
    },
    requestPath: {
      type: 'string',
    },
    requestTypes: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    targetSystems: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    searchCriteria: {
      type: 'string',
    },
    'paging.CurrentPage': {
      type: 'integer',
    },
    'paging.PageSize': {
      type: 'integer',
    },
    'paging.IncludeCount': {
      type: 'boolean',
    },
    'paging.NoPaging': {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
